const API_URL = "https://api.tcup.co.uk";

export default {
    CHECKUP_URL: 'https://checkup.tcup.co.uk',
    DEFAULT_REGION: "eu-west-2",
    mailchimp: {
        unique_id: "403f4ede736685935e5809e48",
        audience_id: "5514829c3c"
    },
    contactUs: {
        EMAIL: "hello@tcup.co.uk"
    },
    s3: {
        BUCKET: "tcup-prod-app-uploads"
    },
    cognito: {
        USER_POOL_ID: "eu-west-2_P3AopUEku",
        APP_CLIENT_ID: "108dm0gi746abc9a6nf0a42f38",
        IDENTITY_POOL_ID: "eu-west-2:65e0899d-3725-49a8-887d-1b9e43bdd08c",
        MFA_ENABLED: true
    },
    apis: {
        userApi: {
            URL: `${API_URL}/users-service`
        },
        userSettingsApi: {
            URL: `${API_URL}/user-settings-service`
        },
        organisationsApi: {
            URL: `${API_URL}/organisations-service`
        },
        questionsApi: {
            URL: `${API_URL}/questions-service`
        },
        statisticsApi: {
            URL: `${API_URL}/statistics-service`
        },
        occupationsApi: {
            URL: `${API_URL}/occupations-service`
        },
        insightsApi: {
            URL: `${API_URL}/insights-service`
        },
        funFactsApi: {
            URL: `${API_URL}/fun-facts-service`
        },
        recognitionsApi: {
            URL: `${API_URL}/recognitions-service`
        },
        notesApi: {
            URL: `${API_URL}/notes-service`
        },
        infoApi: {
            URL: `${API_URL}/info-service`
        },
        resourcesApi: {
            URL: `${API_URL}/resources-service`
        },
        tbucksApi: {
            URL: `${API_URL}/tbucks-service`
        },
        performanceApi: {
            URL: `${API_URL}/performance-service`
        },
        buddyApi: {
            URL: `${API_URL}/buddy-service`
        },
        speakupUsersApi: {
            URL: `${API_URL}/speakup-users-service`
        },
        revenuecatApi: {
            URL: `${API_URL}/revenuecat-service`
        },
        challengeApi: {
            URL: `${API_URL}/challenges-service`
        },
        actionsApi: {
            URL: `${API_URL}/actions-service`
        },
        articlesApi: {
            URL: `${API_URL}/articles-service`
        },
        chatAIApi: {
            URL: `${API_URL}/ai-service`
        },
        chatAIApiTeam: {
            URL: `${API_URL}/ai-service/team`
        }
    }
};
