import styles from './App.module.less';

import React, {Component} from 'react';
import {Button} from "antd";
import {Auth} from 'aws-amplify';
import {Redirect, withRouter} from "react-router";
import {Route, Switch} from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import isEmpty from 'lodash/isEmpty';
import withUser from "../redux/hoc/withUser";
import isUndefined from 'lodash/isUndefined';
import asyncComponent from './hoc/AsyncComponent';
import FullFlexHeight from "./hoc/FullFlexHeight/FullFlexHeight";
import Spinner from './utils/Spinner/Spinner';
import CookieConsentContent from "./hoc/CookieConsentContent";
import MobileScreen from '../components/mobileScreen/MobileScreen';

const AuthView = asyncComponent(() => import("./auth/AuthView/AuthView"));
const SetNewPassword = asyncComponent(() => import("./auth/SetNewPassword/SetNewPassword"));
const SubscriptionExpired = asyncComponent(() => import("./auth/subscription/SubscriptionExpired"));
const PortalHeader = asyncComponent(() => import('./portal/PortalHeader/PortalHeader'));
const PortalFooter = asyncComponent(() => import('./portal/PortalFooter'));
const PortalStartPage = asyncComponent(() => import("./portal/PortalStartPage/PortalStartPage"));
const AboutUs = asyncComponent(() => import('./hoc/footer/AboutUs'));
const FAQ = asyncComponent(() => import('./hoc/footer/FAQ'));
const Accessibility = asyncComponent(() => import('./hoc/footer/Accessibility'));
const Terms = asyncComponent(() => import('./hoc/footer/Terms'));
const PrivacyPolicy = asyncComponent(() => import('./hoc/footer/PrivacyPolicy'));
const CookiePolicy = asyncComponent(() => import('./hoc/footer/CookiePolicy'));
const AcceptableUsePolicy = asyncComponent(() => import('./hoc/footer/AcceptableUsePolicy'));

const MIN_DESKTOP_WIDTH = 320;

class App extends Component {

    constructor(props) {
      super(props);

      this.state = {};

      this.onResize = this.onResize.bind(this);
    }

    async componentDidMount() {
      this.setState({
        isMobile: window.innerWidth < MIN_DESKTOP_WIDTH
      });

      window.addEventListener("resize", this.onResize, false);

        try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            this.setState({
                cognitoUser: cognitoUser
            });
        } catch (err) {
            await Auth.signOut();
            this.setState({
                cognitoUser: null
            });
        }
    }

    onResize() {
      this.setState({
        isMobile: window.innerWidth < MIN_DESKTOP_WIDTH
      });
    }

    nonAuthenticatedRoutes = (
        <div className={styles.main}>
            <PortalHeader/>
            <FullFlexHeight>
                <Switch>
                    <Route exact path={`/`} render={() => <Redirect to={'/login'}/>}/>
                    <Route path={`/login`} render={props => <AuthView {...props} />}/>
                    <Route path={`/new-password`} component={SetNewPassword}/>
                    <Route path={`/expired`} component={SubscriptionExpired}/>
                    <Route path={`/team`} render={props => <AboutUs {...props}/>}/>
                    <Route path={`/faqs`} render={props => <FAQ {...props}/>}/>
                    <Route path={`/privacy-policy`} render={props => <PrivacyPolicy {...props}/>}/>
                    <Route path={`/cookie-policy`} render={props => <CookiePolicy {...props}/>}/>
                    <Route path={`/terms`} render={props => <Terms {...props}/>}/>
                    <Route path={`/accessibility`} render={props => <Accessibility {...props}/>}/>
                    <Route path={`/acceptable-use-policy`} render={props => <AcceptableUsePolicy {...props}/>}/>

                    <Route render={() => <Redirect to='/login'/>}/>
                </Switch>
            </FullFlexHeight>
            <PortalFooter />
        </div>
    );

    authenticatedRoutes = (
      <div className={styles.main}>
        <PortalHeader/>
          <FullFlexHeight>
            <Switch>
              <Route exact path={`/`} render={() => <Redirect to={`/portal`}/>}/>
              <Route path={`/portal`} render={props => <PortalStartPage {...props} cognitoUser={this.state.cognitoUser}/>}/>
              <Route path={`/team`} render={props => <AboutUs {...props}/>}/>
              <Route path={`/faqs`} render={props => <FAQ {...props}/>}/>
              <Route path={`/privacy-policy`} render={props => <PrivacyPolicy {...props}/>}/>
              <Route path={`/cookie-policy`} render={props => <CookiePolicy {...props}/>}/>
              <Route path={`/terms`} render={props => <Terms {...props}/>}/>
              <Route path={`/accessibility`} render={props => <Accessibility {...props}/>}/>
              <Route path={`/acceptable-use-policy`} render={props => <AcceptableUsePolicy {...props}/>}/>
              <Route render={() => <Redirect to='/'/>}/>
            </Switch>
          </FullFlexHeight>
        <PortalFooter />
      </div>
    );

    mobileScreen = (
      <div className={styles.mobileMain}>
        <MobileScreen />
      </div>
  );

    render() {
        if (isUndefined(this.state.cognitoUser)) {
            return (<Spinner/>);
        }
        return (
            <div className={styles.app}>
                {this.isAuthenticated() ? (
                  this.state.isMobile ? this.mobileScreen : this.authenticatedRoutes
                  ) : (
                    this.state.isMobile ? this.mobileScreen : this.nonAuthenticatedRoutes
                  )}
                <CookieConsent
                    disableStyles
                    location='bottom'
                    buttonText="Accept"
                    ButtonComponent={Button}
                >
                    <CookieConsentContent/>
                </CookieConsent>
            </div>
        );
    }

    isAuthenticated = () => (
        !isEmpty(this.state.cognitoUser)
    );
}

export default withRouter(withUser(App));
